import React, { Fragment } from 'react';
import styled from 'styled-components';
import Contact from '../components/Contact';
import PageHero from '../components/PageHero';
import { graphql, useStaticQuery } from 'gatsby';
import SEO from '../components/SEO';

const Wrapper = styled.div``;

const contactPageQuery = graphql`
  {
    datoCmsContact {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`;

export default function ContactPage() {
  const d = useStaticQuery(contactPageQuery);
  return (
    <Fragment>
      <SEO meta={d.datoCmsContact.seoMetaTags} />
      <Wrapper>
        <PageHero title="Hello. Nice to meet you." />
        <Contact />
      </Wrapper>
    </Fragment>
  );
}
